import HarmonogramTerms from '../components/HarmonogramTerms';
import HarmonogramItems from '../components/HarmonogramItems';
import MainPhoto from '../components/Main-Photo';
import Nagrody from '../components/Nagrody';
import WezUdzial from '../components/WezUdzial';

const Harmonogram = () => {
    return ( 
        <>
            <MainPhoto title="Harmonogram i zasady" bg="chtp-main-photo--harmonogram"/>
            <div className="site-container chtp__container-padding">
                <h2 className="chtp-harmonogram__title">Harmonogram</h2>
                <HarmonogramItems />
                <HarmonogramTerms />
                <Nagrody />
                <WezUdzial />
            </div>
        </>
     );
}
 
export default Harmonogram