import { useState } from "react";

interface NumberProps {
    num: number;
    desc: string;
}

const ChtpNumbers = () => {
    const number1 = {num: 1500, desc: 'tyle marzeń'}
    const number2 = {num: 2500, desc: 'tyle wrażeń'}
    const number3 = {num: 5500, desc: 'tyle przeróżnych zdarzeń'}

    const [numbers, setNumbers] = useState<NumberProps[]>([number1, number2, number3]);

    return ( 
        <div className="chtp__chtp-numbers">
            {numbers.map((item) => (
                <div className="chtp__chtp-numbers-item" key={item.desc}>
                    <p className="chtp__chtp-numbers-item-num">[ <b>{ item.num }</b> ]</p>
                    <p className="chtp__chtp-numbers-item-text">{ item.desc}</p>
                </div>
            ))}
        </div>
     );
}
 
export default ChtpNumbers;