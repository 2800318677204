import { useState } from 'react'

// router components
import { useNavigate, NavLink} from 'react-router-dom'

import ScrollToTop from './ScrollToTop'


import KM_IMG from '../assets/LOGO KM.svg'
import CHTP_IMG from '../assets/LOGO_CHTP.svg'
import FB_LOGO from '../assets/fb.png'
import IG_LOGO from '../assets/ig.png'


function Header() {
    let [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const navigate = useNavigate()

    const handleBurgerClick = () => {
      setMobileMenuOpen(!mobileMenuOpen)
    }


    return (
      <>
      <div className="chtp-header ">
        <ScrollToTop />
        <div className={`chtp-header__container ${mobileMenuOpen && 'chtp-header__nav-list--mobile-container--active'}`}>
          <nav className="chtp-header__nav">
            <div className="chtp-header__logos">
              <NavLink to="/"><img src={CHTP_IMG} alt="chtp logo" /></NavLink>
              <span>by</span>
              <a href="https://katalogmarzen.pl" target="_blank">
                <img src={KM_IMG} alt="chtp katalog marzeń" />
                </a>
            </div>
            <ul className="chtp-header__nav-list--desktop">
              <NavLink to="o-akcji"><li>O akcji</li></NavLink>
              <NavLink to="partnerzy"><li>Partnerzy</li></NavLink>
              <NavLink to="harmonogram"><li>Harmonogram i zasady</li></NavLink>
              <NavLink to="kim-jestesmy"><li>Kim jesteśmy?</li></NavLink>
            </ul>
            <ul className="chtp-header__nav-list--desktop-socials">
              <li><img src={FB_LOGO} alt="" /></li>
              <li><a href="https://www.instagram.com/chcetoprzezyc/" target="_blank"><img src={IG_LOGO} alt="" /></a></li>
              <NavLink to="logowanie"><button className="chtp__button chtp__white-button">ZALOGUJ SIĘ</button></NavLink>
            </ul>
            <div onClick={handleBurgerClick} className="chtp-header__burger">
              <div className="chtp-header__burger-line"></div>
            </div>
          </nav>
        </div>
        <div className={`chtp-header__nav-list--mobile-container ${mobileMenuOpen && 'chtp-header__nav-list--mobile-container--active'}`}>
          <ul className="chtp-header__nav-list--mobile">
              <NavLink to="o-akcji"><li onClick={handleBurgerClick}>O akcji</li></NavLink>
              <NavLink to="partnerzy"><li onClick={handleBurgerClick}>Partnerzy</li></NavLink>
              <NavLink to="harmonogram"><li onClick={handleBurgerClick}>Harmonogram i zasady</li></NavLink>
              <NavLink to="kim-jestesmy"><li onClick={handleBurgerClick}>Kim jesteśmy?</li></NavLink>
          </ul>
            <NavLink to="logowanie"><button onClick={handleBurgerClick} className="chtp-header__zaloguj-button">ZALOGUJ SIĘ</button></NavLink>
          <div className="chtp-header__socials">
            <img
              src="https://b2b.katalogmarzen.pl/wp-content/uploads/2020/04/1.jpg"
              alt=""
            />
            <img
              src="https://b2b.katalogmarzen.pl/wp-content/uploads/2020/04/1.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      </>
    )
  }

  export default Header
