interface Props {
    text: string;
    link: string
}

const WhiteButton = ({text, link}:Props) => {
    return ( 
        <a href={link} target="_blank"><button className="chtp__button chtp__white-button"><b>{text}</b></button></a>
     );
}
 
export default WhiteButton;