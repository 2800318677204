const Przypomnij = () => {
    return (
        <div className="chtp-zaloguj chtp-przypomnij">
            <h4 className="chtp-zaloguj__title">Podaj adres email, wyślemy Ci link do przypomnienia hasła</h4>
            <div className="site-container">
                <form className="chtp-zaloguj__form" action="">
                    <input type="text" placeholder="Adres e-mail"/>
                    <input className="chtp__button" type="submit" value="PRZYPOMNIJ HASŁO" />
                </form>
            </div>
        </div>
     );
}

export default Przypomnij;