import MainPhoto from '../components/Main-Photo';

const ZglosSie = () => {
    return ( 
        <div className="chtp-zglos-sie">
        <MainPhoto title="WEŹ UDZIAŁ W KONKURSIE" bg="chtp-main-photo--zglos-sie"/>
        <div className="site-container">
            <h2 className="chtp-zglos-sie__title">Lorem ipsum dolor sit amet</h2>
            <p className="chtp-zglos-sie__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias labore delectus ipsa? Sequi aliquid aliquam a quod rem dignissimos magnam itaque enim nobis explicabo quasi, porro dolores, deserunt tempora. Illo?</p>
            <form className="chtp-zglos-sie__form" action="">
                <div className="chtp-zglos-sie__form-info">
                    <input type="text" placeholder="Imię"/>
                    <input type="text" placeholder="Nazwisko"/>
                    <input type="email" placeholder="Adres e-mail"/>
                    <select name="city" id="city">
                        <option value="city" disabled selected>Miasto</option>
                        <option value="parczew">Parczew</option>
                        <option value="warsaw">Warszawa</option>
                        <option value="trójmiasto">Trójmiasto</option>
                        <option value="zakopane">Zakopane</option>
                        <option value="jarosław">Jarosław</option>
                    </select>
                </div>
                <textarea name="" id="" placeholder="Treść zgłoszenia *"></textarea>
                <div className="chtp-zglos-sie__form-links">
                    <input type="text" id="chtp-form__facebook" placeholder="Link do Twojego profilu na Facebooku *"/>
                    <input type="text" id="chtp-form__instagram" placeholder="Link do Twojego profilu na Instagramie *"/>
                    <input type="text" id="chtp-form__tiktok" placeholder="Link do Twojego profilu na TikToku *"/>
                    <input type="text" id="chtp-form__other" placeholder="Link do Twojego Bloga, YouTuba itd *"/>
                </div>
                <div className="chtp-zglos-sie__form-checkbox-section">
                    <input type="checkbox" id="acceptance" name="acceptance" required/>
                    <label htmlFor="">
                    Akceptuję regulamin i politykę prywatności oraz zapoznałam /zapoznałem się z informacją dot. przetwarzania danych osobowych przez KatalogMarzeń.pl Joanna Zubik-Borucka, Tomasz Zubik s.c.
                    </label>
                </div>
                <p>* - tego pola nie musisz wypełniać już dziś. Będziesz mógł je wypełnić na swoim koncie do momentu startu konkursu</p>
                <div className="chtp-zglos-sie__form-button-container"><input className="chtp__button" type="submit" value={"WCHODZĘ PO MARZENIA >"} /></div>
            </form>
        </div>
        </div>
     );
}

export default ZglosSie;