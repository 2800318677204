import MainPhoto from '../components/Main-Photo';
import WezUdzial from '../components/WezUdzial';

const page404 = () => {
    return ( 
        <div className="chtp-404">
        <MainPhoto title="404" bg="chtp-main-photo--404"/>
        <div className="site-container">
            <h2 className="chtp-404__title">Lorem ipsum dolor sit amet</h2>
            <p className="chtp-404__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias labore delectus ipsa? Sequi aliquid aliquam a quod rem dignissimos magnam itaque enim nobis explicabo quasi, porro dolores, deserunt tempora. Illo?</p>
            <WezUdzial />
        </div>
        </div>
     );
}
 
export default page404;