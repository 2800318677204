import MainPhoto from "../components/Main-Photo";
import WhiteButton from "../components/WhiteButton";

import photo1 from "../assets/partnerzy_1.jpg";
import activities1 from "../assets/kim-jestesmy-activities1.jpg";
import activities2 from "../assets/kim-jestesmy-activities2.jpg";
import activities3 from "../assets/kim-jestesmy-activities3.jpg";
import facebook from "../assets/social-facebook.svg";
import instagram from "../assets/social-instagram.svg";
import youtube from "../assets/social-youtube.svg";
import tiktok from "../assets/social-tiktok.svg";
import katalog_white from "../assets/LOGO KM_white.svg";
import number1 from "../assets/kim-jestesmy-number1.png";
import number2 from "../assets/kim-jestesmy-number2.png";
import number3 from "../assets/kim-jestesmy-number3.png";
import stars from "../assets/stars.svg";
import WezUdzial from "../components/WezUdzial";

const KimJestesmy = () => {
  const yearsOnMarket = "12";
  const fulfilledDreams = "250 000";
  const attractions = "6 500";

  return (
    <>
      <MainPhoto title="Kim jesteśmy" bg="chtp-main-photo--kim-jestesmy" />
      <div className="site-container chtp__container-padding">
        <div className="chtp__text-photo">
          <div className="chtp__text-photo-text">
            <h2>Za kulisami akcji #ChcęToPrzeżyć</h2>
            <p>
              To my, zespół{" "}
              <a href="https://katalogmarzen.pl/" target="_blank">
                Katalogu Marzeń
              </a>
              . Oferujemy prezenty w formie przeżyć — przygotowaliśmy dla Ciebie
              ponad 6500 atrakcji w całej Polsce, które świetnie sprawdzą się
              jako prezenty dla Twoich bliskich.
            </p>
            <p>
              W naszym zespole znajdziesz skoczków spadochronowych, wielbicieli
              jazdy konnej, zdobywców górskich szczytów i miłośników czynienia
              dobra. Na marzeniach znamy się jak nikt inny i tę wiedzę
              wykorzystujemy, by wspierać innych.
            </p>
            <p>
              Uwielbiamy otaczać się ludźmi z pasją i pomagać im sięgać po
              kolejne marzenia. Robimy to dzięki takim akcjom jak
              #ChcęToPrzeżyć,{" "}
              <a className="underline-link" href="https://maratonmarzen.pl/" target="_blank">
                Maraton Marzeń
              </a>
              ,{" "}
              <a className="underline-link" href="https://dreamanddo.pl/" target="_blank">
                Dream and Do
              </a>
              ,{" "}
              <a className="underline-link" href="https://marzycielepomagaja.pl/" target="_blank">
                Marzyciele Pomagają{" "}
              </a>
              i{" "}
              <a className="underline-link" href="https://marzeniabezbarier.pl/" target="_blank">
                Marzenia Bez Barier.
              </a>
            </p>
          </div>
          <img className="chtp__text-photo-photo" src={photo1} alt="" />
        </div>
        <div className="chtp__text-photo chtp__text-photo--reverse">
          <div className="chtp__text-photo-text">
            <h2>Nasza misja to coś więcej niż prezenty</h2>
            <p>
              W Katalogu Marzeń wierzymy, że przeżycia i wspólne spędzanie czasu
              dają więcej radości, niż jakakolwiek rzecz, którą posiadamy.
              Dlatego codziennie pomagamy Ci spełniać marzenia bliskich, ale też
              spełniamy nasze własne
            </p>
            <p>
              Jak wygląda praca w firmie, która spełnia marzenia? Jest pełna
              wyzwań i radości. Testujemy atrakcje, które trafiają do naszej
              oferty, snujemy szalone plany podczas wspólnych lunchy, rozwijamy
              swoje kompetencje i dbamy o dobrą atmosferę. Świętujemy sukcesy z
              lampką prosecco. Więcej o naszym Dream Team przeczytasz na stronie
              poświęconej{" "}
              <a className="underline-link" href="https://kariera.katalogmarzen.pl/" target="_blank">
                pracy w Katalogu Marzeń
              </a>
              .
            </p>
          </div>
          <img className="chtp__text-photo-photo" src={photo1} alt="" />
        </div>
        <section className="kim-jestesmy__activities-columns">
          <div className="kim-jestesmy__activities-item">
            <img src={activities1} alt="" />
            <div className="kim-jestesmy__activities-item-text">
              <h2>Maraton Marzeń</h2>
              <p>
                To „wyścig”, w którym spełniamy marzenia. Na czym polega?
                Uczestnicy Maratonu zgłaszają swoje marzenia, każdy pomysł się
                liczy. Najciekawsze projekty rozwijamy pod opieką osobistych
                Mentorów, którzy pomagają stworzyć plan i dzielą się swoją
                wiedzą. Główną nagrodą w Maratonie Marzeń jest dofinansowanie w
                wysokości 10 000 złotych.
              </p>
              <WhiteButton
                text="Dowiedz się więcej"
                link="https://maratonmarzen.pl/#o-projekcie"
              />
            </div>
          </div>
          <div className="kim-jestesmy__activities-item">
            <img src={activities2} alt="" />
            <div className="kim-jestesmy__activities-item-text">
              <h2>Napędzani Marzeniami</h2>
              <p>
                Podcast, w którym Joanna Borucka (CEO Katalogu Marzeń) rozmawia
                z gośćmi o marzeniach. Autentyczne historie o spełnionych
                marzeniach pobudzą Cię do działania!
              </p>
              <p>
                Oprócz niesamowitych opowieści znajdziesz tutaj również
                praktyczne wskazówki, które pomogą Ci spełnić Twoje marzenia. To
                lekcje prosto od zdobywców górskich szczytów, podróżników,
                biznesmenów i artystów, dzięki którym uwierzysz, że chcieć
                znaczy móc.
              </p>
              <WhiteButton
                text="Posłuchaj podcastu"
                link="https://napedzanimarzeniami.pl/"
              />
            </div>
          </div>
          <div className="kim-jestesmy__activities-item">
            <img src={activities3} alt="" />
            <div className="kim-jestesmy__activities-item-text">
              <h2>Dream And Do</h2>
              <p>
                Są takie marzenia, których spełnienie wymaga wysokich nakładów
                finansowych. My wiemy, że spełnione marzenia sprawiają, że świat
                staje się lepszy, więc uruchomiliśmy{" "}
                <b>stypendia dla Marzycieli</b>.
              </p>
              <p>
                Na Dream and Do znajdziesz produkty, które powstały, by wesprzeć
                realizację marzeń. Kupując je, pomagasz nam realizować projekty
                odważnych ludzi. Może zrealizujemy wspólnie też Twoje marzenie?
              </p>
              <WhiteButton
                text="Dowiedz się więcej"
                link="https://dreamanddo.pl/"
              />
            </div>
          </div>
        </section>
      </div>
      <section className="kim-jestesmy__numbers chtp__container-padding">
        <div className="site-container">
          <img
            className="kim-jestesmy__numbers-km-logo"
            src={katalog_white}
            alt=""
          />
          <div className="kim-jestesmy__numbers-columns">
            <div className="kim-jestesmy__numbers-item">
              <img src={number1} alt="" />
              <p>{`${yearsOnMarket} lat na rynku`}</p>
            </div>
            <div className="kim-jestesmy__numbers-item">
              <img src={number2} alt="" />
              <p>{`${fulfilledDreams} spełnionych marzeń`}</p>
            </div>
            <div className="kim-jestesmy__numbers-item">
              <img src={number3} alt="" />
              <p>{`${attractions} `} atrakcji w&nbsp;ofercie</p>
            </div>
          </div>
        </div>
      </section>
      <div className="site-container chtp__container-padding">
        <section className="kim-jestesmy__social-columns">
          <div className="kim-jestesmy__social-item">
            <div className="kim-jestesmy__social-logo-container">
              <div className="kim-jestesmy__social-logo">
                <img src={facebook} alt="" />
              </div>
            </div>
            <p className="kim-jestesmy__social-title">
              <b>Facebook</b>
            </p>
            <p className="kim-jestesmy__social-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
              architecto sunt nam, quos accusantium exercitationem quasi sed
              expedita tempore iure natus magni illum optio unde ducimus
              voluptas rem! Temporibus, voluptatem.
            </p>
            <WhiteButton
              text="sprawdź"
              link="https://www.facebook.com/KatalogMarzenpl"
            />
          </div>
          <div className="kim-jestesmy__social-item">
            <div className="kim-jestesmy__social-logo-container">
              <div className="kim-jestesmy__social-logo">
                <img src={instagram} alt="" />
              </div>
            </div>
            <p className="kim-jestesmy__social-title">
              <b>Instagram</b>
            </p>
            <p className="kim-jestesmy__social-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
              architecto sunt nam, quos accusantium exercitationem quasi sed
              expedita tempore iure natus magni illum optio unde ducimus
              voluptas rem! Temporibus, voluptatem.
            </p>
            <WhiteButton
              text="sprawdź"
              link="https://www.instagram.com/katalogmarzen.pl/?hl=pl"
            />
          </div>
          <div className="kim-jestesmy__social-item">
            <div className="kim-jestesmy__social-logo-container">
              <div className="kim-jestesmy__social-logo">
                <img src={youtube} alt="" />
              </div>
            </div>
            <p className="kim-jestesmy__social-title">
              <b>YouTube</b>
            </p>
            <p className="kim-jestesmy__social-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
              architecto sunt nam, quos accusantium exercitationem quasi sed
              expedita tempore iure natus magni illum optio unde ducimus
              voluptas rem! Temporibus, voluptatem.
            </p>
            <WhiteButton
              text="sprawdź"
              link="https://www.youtube.com/@katalog-marzen"
            />
          </div>
          <div className="kim-jestesmy__social-item">
            <div className="kim-jestesmy__social-logo-container">
              <div className="kim-jestesmy__social-logo">
                <img src={tiktok} alt="" />
              </div>
            </div>
            <p className="kim-jestesmy__social-title">
              <b>TikTok</b>
            </p>
            <p className="kim-jestesmy__social-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
              architecto sunt nam, quos accusantium exercitationem quasi sed
              expedita tempore iure natus magni illum optio unde ducimus
              voluptas rem! Temporibus, voluptatem.
            </p>
            <WhiteButton
              text="sprawdź"
              link="https://www.tiktok.com/@katalogmarzen"
            />
          </div>
        </section>
        <section className="kim-jestesmy__opinions">
          <div className="kim-jestesmy__opinions-columns">
            <div className="kim-jestesmy__opinions-text">
              <h2>Opinie o Katalogu Marzeń</h2>
              <p>
                Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
              </p>
              <WhiteButton
                text="sprawdź opinie"
                link="https://www.google.com/maps/place/Katalog+Marze%C5%84/@52.2511419,21.0428793,17z/data=!4m7!3m6!1s0x471933315890e07f:0xae7cdff6b7940ad1!8m2!3d52.2511004!4d21.0451299!9m1!1b1"
              />
            </div>
            <div className="kim-jestesmy__opinions-stars-container">
              <p>
                <b>4,8/5</b>
              </p>
              <img src={stars} alt="" />
              <WhiteButton
                text="sprawdź opinie"
                link="https://www.google.com/maps/place/Katalog+Marze%C5%84/@52.2511419,21.0428793,17z/data=!4m7!3m6!1s0x471933315890e07f:0xae7cdff6b7940ad1!8m2!3d52.2511004!4d21.0451299!9m1!1b1"
              />
            </div>
          </div>
        </section>
        <WezUdzial />
      </div>
    </>
  );
};

export default KimJestesmy;
