import MainPhoto from "../components/Main-Photo";
import ChtpNumbers from "../components/ChtpNumbers";
import GridGallery from "../components/GridGallery";

import photo1 from "../assets/partnerzy_1.jpg";
import WezUdzial from "../components/WezUdzial";

const OAkcji = () => {
  return (
    <>
      <MainPhoto title="O akcji" bg="chtp-main-photo--o-akcji" />
      <div className="site-container chtp__container-padding">
        <div className="chtp__text-photo">
          <div className="chtp__text-photo-text">
            <h2>Jak narodziła się akcja #ChcęToPrzeżyć?</h2>
            <p>
              Od 2017 roku spełniamy marzenia śmiałków, których z dumą nazywamy
              Testerami Marzeń! #ChcęToPrzeżyć to akcja, w którą chętnie
              angażują się Marzyciele z całej Polski, możesz być jednym z nich.
            </p>
            <p>
              Czasami, by spełnić marzenie, potrzebujemy małego impulsu. W akcji
              #ChcęToPrzeżyć oddajemy całkiem za darmo w ręce odważnych
              Marzycieli vouchery na przeżycia. W akcji wyłaniamy zwycięską
              trójkę uczestników, którzy dostają od nas abonament na spełnianie
              marzeń. Dlaczego? Bo chcemy, by każdy miał szansę spełnić
              marzenie!
            </p>
            <p>
              #ChcęToPrzeżyć to już ponad 3000 zrealizowanych marzeń, ponad 200
              zaangażowanych Partnerów i ponad 2500 Testerów Marzeń w różnym
              wieku.
            </p>
          </div>
          <img className="chtp__text-photo-photo" src={photo1} alt="" />
        </div>
        <div className="chtp__text-photo chtp__text-photo--reverse">
          <div className="chtp__text-photo-text">
            <h2>Obserwuj Marzycieli w akcji</h2>
            <p>
              Zadaniem naszych Testerów Marzeń jest doświadczanie radości i
              przekraczanie własnych granic. Ich przygody możesz obserwować w
              mediach społecznościowych. Podczas realizacji atrakcji z oferty
              nagrywają filmy, robią zdjęcia i opowiadają o swoich przeżyciach.
              Publikują relacje, posty, wpisy blogowe i tworzą jedyną w swoim
              rodzaju społeczność.
            </p>
            <p>
              W każdej edycji dzielimy radość z Marzycielami i pokazujemy ich
              niezwykłe wyczyny. Cała akcja #ChcęToPrzeżyć dzieli się na 3
              etapy, w których wyłaniam kolejnych uczestników, którzy mają
              szansę wygrać jeszcze więcej spełnionych marzeń.
            </p>
          </div>
          <img className="chtp__text-photo-photo" src={photo1} alt="" />
        </div>
        <div className="chtp__text-photo">
          <div className="chtp__text-photo-text">
            <h2>Chcesz to przeżyć? Dołącz! </h2>
            <p>
              Ty też możesz zostać Testerem Marzeń i wygrać abonament na
              spełnianie swoich pragnień. Wystarczy, że wypełnisz formularz i
              wyślesz do nas swoje zgłoszenie. Nabór Testerów prowadzimy na
              początku roku.
            </p>
            <p>
              Obserwuj nasze konta w mediach społecznościowych, gdzie ogłosimy
              start kolejnej edycji i pokażemy Ci z bliska zmagania Testerów.
            </p>
            <p>To, jakie marzenie spełnisz?</p>
          </div>
          <img className="chtp__text-photo-photo" src={photo1} alt="" />
        </div>
        <ChtpNumbers />
        <GridGallery />
        <WezUdzial />
      </div>
    </>
  );
};

export default OAkcji;
