const NoweHaslo = () => {
    return (
        <div className="chtp-zaloguj chtp-przypomnij">
            <h4 className="chtp-zaloguj__title">Wpisz nowe hasło</h4>
            <div className="site-container">
                <form className="chtp-zaloguj__form" action="">
                    <input type="password" placeholder="nowe hasło"/>
                    <input type="password" placeholder="powtórz hasło"/>
                    <input className="chtp__button" type="submit" value="RESETUJ HASŁO" />
                </form>
            </div>
        </div>
     );
}

export default NoweHaslo;