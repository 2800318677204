// import React from 'react';
import './App.sass';

// router components
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

// rooter layouts
// import RootLayout from './layouts/RootLayout';

// views
import Home from './views/Home';
import Error404 from './views/Error404';
import KimJestesmy from './views/Kim-Jestesmy';
import Harmonogram from './views/Harmonogram';
import Partnerzy from './views/Partnerzy';
import OAkcji from './views/O-Akcji';
import ZglosSie from './views/Zglos-Sie';
import ZalogujSie from './views/Zaloguj-Sie';
import Przypomnij from './views/Przypomnij';
import NoweHaslo from './views/NoweHaslo';

// components
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'

// layouts
import RootLayout from './layouts/RootLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />} >
        <Route index element={<Home />} />
        <Route path="/kim-jestesmy" element={<KimJestesmy />} />
        <Route path="/harmonogram" element={<Harmonogram />} />
        <Route path="/partnerzy" element={<Partnerzy />} />
        <Route path="/o-akcji" element={<OAkcji />} />
        <Route path="/zglos-sie" element={<ZglosSie />} />
        <Route path="/logowanie" element={<ZalogujSie />}/>
        <Route path="/przypomnienie-hasla" element={<Przypomnij />}/>
        <Route path="/nowe-haslo" element={<NoweHaslo />}/>
        <Route path="*" element={<Error404 />}/>
      </Route>
    </>
))

function App() {
  return (
    <div className="chtp">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
