import { useState } from 'react'

import MainPhoto from '../components/Main-Photo';
import WezUdzial from '../components/WezUdzial';

import photo1 from '../assets/partnerzy_1.jpg'
import FreeFlyCenter from '../assets/partner-logo/free-fly-center.png'
import GearUp from '../assets/partner-logo/gear-up.png'
import GrupaX from '../assets/partner-logo/grupaX.png'
import IbexAeroFlyCenter from '../assets/partner-logo/ibex-aero.png'
import Jokar from '../assets/partner-logo/jokar.png'
import KuwakaWake from '../assets/partner-logo/kuwaka-wake.png'
import LotyMarzen from '../assets/partner-logo/loty-marzen.png'
import ISurf from '../assets/partner-logo/lsurf.png'

const Partnerzy = () => {
    const [logos, setLogos] = useState([
        FreeFlyCenter, GearUp, GrupaX, IbexAeroFlyCenter, Jokar, KuwakaWake, LotyMarzen, ISurf,FreeFlyCenter, GearUp, GrupaX, IbexAeroFlyCenter, Jokar, KuwakaWake, LotyMarzen, ISurf,FreeFlyCenter, GearUp, GrupaX, IbexAeroFlyCenter, Jokar, KuwakaWake, LotyMarzen, ISurf,FreeFlyCenter, GearUp, GrupaX, IbexAeroFlyCenter, Jokar, KuwakaWake, LotyMarzen, ISurf,
    ])


    return (
        <>
            <MainPhoto title="Partnerzy" bg="chtp-main-photo--partnerzy"/>
            <div className="site-container chtp__container-padding">
                <div className="chtp__text-photo">
                    <div className="chtp__text-photo-text">
                        <h2>Lorem ipsum dolor sit amet.</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis qui fugiat architecto mollitia iusto aut porro consequatur recusandae iure aspernatur?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis qui fugiat architecto mollitia iusto aut porro consequatur recusandae iure aspernatur?</p>
                    </div>
                    <img className="chtp__text-photo-photo" src={photo1} alt="" />
                </div>
                <section className="chtp-partners__logos">
                    { logos.map((item):any => (
                        <img src={item} alt="" />
                    ))
                    }
                </section>
                <div className="chtp__text-photo">
                    <div className="chtp__text-photo-text">
                        <h2>Lorem ipsum dolor sit amet.</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis qui fugiat architecto mollitia iusto aut porro consequatur recusandae iure aspernatur?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis qui fugiat architecto mollitia iusto aut porro consequatur recusandae iure aspernatur?</p>
                    </div>
                    <img className="chtp__text-photo-photo" src={photo1} alt="" />
                </div>
                <WezUdzial />
            </div>
        </>
     );
}
 
export default Partnerzy;