const HarmonogramItems = () => {
    return ( 
        <div className="chtp-harmonogram__items">
        <ul className="chtp-harmonogram__items-numbers">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <div className="chtp-harmonogram__item">
            <div className="chtp-harmonogram__item-inner">
                <p className="chtp-harmonogram__item-date"><b>07.11.2022r</b></p>
                <p className="chtp-harmonogram__item-title"><b>PRZYJMOWANIE ZGŁOSZEŃ</b></p>
                <p className="chtp-harmonogram__item-text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div className="chtp-harmonogram__item">
            <div className="chtp-harmonogram__item-inner">
                <p className="chtp-harmonogram__item-date"><b>07.11.2022r</b></p>
                <p className="chtp-harmonogram__item-title"><b>PRZYJMOWANIE ZGŁOSZEŃ</b></p>
                <p className="chtp-harmonogram__item-text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div className="chtp-harmonogram__item">
            <div className="chtp-harmonogram__item-inner">
                <p className="chtp-harmonogram__item-date"><b>07.11.2022r</b></p>
                <p className="chtp-harmonogram__item-title"><b>PRZYJMOWANIE ZGŁOSZEŃ</b></p>
                <p className="chtp-harmonogram__item-text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div className="chtp-harmonogram__item">
            <div className="chtp-harmonogram__item-inner">
                <p className="chtp-harmonogram__item-date"><b>07.11.2022r</b></p>
                <p className="chtp-harmonogram__item-title"><b>PRZYJMOWANIE ZGŁOSZEŃ</b></p>
                <p className="chtp-harmonogram__item-text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div className="chtp-harmonogram__item">
            <div className="chtp-harmonogram__item-inner">
                <p className="chtp-harmonogram__item-date"><b>07.11.2022r</b></p>
                <p className="chtp-harmonogram__item-title"><b>PRZYJMOWANIE ZGŁOSZEŃ</b></p>
                <p className="chtp-harmonogram__item-text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div className="chtp-harmonogram__item">
            <div className="chtp-harmonogram__item-inner">
                <p className="chtp-harmonogram__item-date"><b>07.11.2022r</b></p>
                <p className="chtp-harmonogram__item-title"><b>PRZYJMOWANIE ZGŁOSZEŃ</b></p>
                <p className="chtp-harmonogram__item-text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
    </div>
     );
}
 
export default HarmonogramItems;