import React from "react";

import Header from "../components/Header"
import Footer from "../components/Footer"

import { Outlet } from "react-router-dom";


const RootLayout: React.FunctionComponent = () => {
    return ( 
        <>
            <Header />
            <div className="chtp__main">
                <Outlet />
            </div>
            <Footer />
        </>
     );
}
 
export default RootLayout;