import Button from './Button'
import { Link } from 'react-router-dom'

const WezUdzial = () => {
    return (
        <div className="chtp__take-part">
            <Link to="/zglos-sie">
                <Button text="weź udział w konkursie >" link="/"/>
            </Link>
        </div>
     );
}

export default WezUdzial;