import { Link } from 'react-router-dom'

import WezUdzial from '../components/WezUdzial';

import GLogo from '../assets/google_logo.png';
import FLogo from '../assets/Flogo.svg';

const Zalogujsie = () => {
    return (
        <div className="chtp-zaloguj">
            <h2 className="chtp-zaloguj__title">ZALOGUJ SIĘ</h2>
            <div className="site-container">
                <form className="chtp-zaloguj__form" action="">
                    <input type="text" placeholder="Adres e-mail"/>
                    <input type="password" placeholder="Hasło"/>
                    <input className="chtp__button" type="submit" value="WCHODZĘ PO MARZENIA" />
                </form>
                <div className="chtp-zaloguj__google-button">
                    <img src={GLogo} alt="" />
                    <span>Sign In with Google</span>
                </div>
                <div className="chtp-zaloguj__facebook-button">
                    <img src={FLogo} alt="" />
                    <span>Continue with Facebook</span>
                </div>
                <Link to="/przypomnienie-hasla"><p className="chtp-zaloguj__recovery-text"><u>{'Nie pamiętam hasła >'}</u></p></Link>
            </div>
            <div className="chtp-zaloguj__wez-udzial-container">
                <div className="site-container">
                    <p>Nie masz jeszcze konta?</p>
                    <p>Wypełnij formularz zgłoszeniowy!</p>
                    <WezUdzial />
                </div>
            </div>
        </div>
     );
}

export default Zalogujsie;