import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Hash from "../assets/chtp-hash.svg";

// import required modules
import { Pagination, Navigation, HashNavigation } from "swiper";


const Nagrody = () => {
    return ( 
        <div className="chtp__nagrody">
          <div className="chtp__nagrody-title-container">
            <img src={Hash}></img>
            <h2 className="chtp__nagrody-title">Nagrody</h2>
          </div>
      <Swiper
        spaceBetween={30}
        hashNavigation={{
          watchState: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, HashNavigation]}
        className="mySwiper"
      >
        <SwiperSlide data-hash="slide1">
            <p>NAGRODA GŁÓWNA</p>
            <p>2400 PLN</p>
        </SwiperSlide>
        <SwiperSlide data-hash="slide2">
            <p>NAGRODA GŁÓWNA</p>
            <p>2400 PLN</p>
        </SwiperSlide>
        <SwiperSlide data-hash="slide3">
            <p>NAGRODA GŁÓWNA</p>
            <p>2400 PLN</p>
        </SwiperSlide>
        <SwiperSlide data-hash="slide4">
            <p>NAGRODA GŁÓWNA</p>
            <p>2400 PLN</p>
            </SwiperSlide>
        <SwiperSlide data-hash="slide5">
            <p>NAGRODA GŁÓWNA</p>
            <p>2400 PLN</p>
        </SwiperSlide>
      </Swiper>
        </div>
     );
}
 
export default Nagrody;