import HarmonogramPhoto1 from '../assets/harmonogram_v4-03.svg'
import HarmonogramPhoto2 from '../assets/harmonogram_v4-04.svg'
import HarmonogramPhoto3 from '../assets/harmonogram_v4-05.svg'
import HarmonogramPhoto4 from '../assets/harmonogram_v4-06.svg'
import HarmonogramArrow from '../assets/harmonogram-arrow-mobile.svg'
import HarmonogramArrowDesktop1 from '../assets/harmonogram-arrow-desktop-1.svg'
import HarmonogramArrowDesktop2 from '../assets/harmonogram-arrow-desktop-2.svg'
import HarmonogramArrowDesktop3 from '../assets/harmonogram-arrow-desktop-3.svg'

const HarmonogramTerms = () => {
    return ( 
        <div className="chtp-harmonogram__terms">
            <h2 className="chtp-harmonogram__terms-title">Zasady konkursu</h2>
            <div className="chtp-harmonogram__terms-container">
                <div className="chtp-harmonogram__terms-item chtp-harmonogram__terms-item1">
                    <img src={HarmonogramPhoto3} className="chtp-harmonogram__terms-item-photo" alt="" />
                    <p className="chtp-harmonogram__terms-item-text">Zgłoś się. Przekonaj nas, że to Ty będziesz najlepszym testerem marzeń!</p>
                    <img src={HarmonogramArrowDesktop1} alt="" className="chtp-harmonogram__arrow-desktop chtp-harmonogram__arrow-desktop1" />
                </div>
                <div className="chtp-harmonogram__arrow-container-mobile">
                    <img src={HarmonogramArrow} alt="" className="chtp-harmonogram__arrow-mobile" />
                </div>
                <div className="chtp-harmonogram__terms-item chtp-harmonogram__terms-item2">
                    <img src={HarmonogramPhoto4} className="chtp-harmonogram__terms-item-photo" alt="" />
                    <p className="chtp-harmonogram__terms-item-text">Wybierz i zrealizuj marzenie</p>
                </div>
                <div className="chtp-harmonogram__arrow-container-mobile">
                    <img src={HarmonogramArrow} alt="" className="chtp-harmonogram__arrow-mobile" />
                </div>
                <div className="chtp-harmonogram__terms-item chtp-harmonogram__terms-item3">
                    <img src={HarmonogramPhoto2} className="chtp-harmonogram__terms-item-photo" alt="" />
                    <p className="chtp-harmonogram__terms-item-text">Pochwal się, jak było! Opublikuj relacje w social mediach z odpowiednimi oznaczeniami.</p>
                    <img src={HarmonogramArrowDesktop3} alt="" className="chtp-harmonogram__arrow-desktop chtp-harmonogram__arrow-desktop2" />
                    <img src={HarmonogramArrowDesktop2} alt="" className="chtp-harmonogram__arrow-desktop chtp-harmonogram__arrow-desktop3" />
                </div>
                <div className="chtp-harmonogram__arrow-container-mobile">
                    <img src={HarmonogramArrow} alt="" className="chtp-harmonogram__arrow-mobile" />
                </div>
                <div className="chtp-harmonogram__terms-item chtp-harmonogram__terms-item4">
                    <img src={HarmonogramPhoto1} className="chtp-harmonogram__terms-item-photo" alt="" />
                    <p className="chtp-harmonogram__terms-item-text">Przechodź do kolejnych etapów i realizuj kolejne marzenia. WALCZ O NAGRODĘ GŁÓWNĄ: ROCZNY ABONAMENT NA SPEŁNIANIE MARZEŃ</p>
                </div>
            </div>
        </div>
     );
}
 
export default HarmonogramTerms;