interface Props {
    text: string;
    link: string
}

const Button = ({text, link}: Props) => {
    return ( 
        <a href={link}><button className="chtp__button">{text}</button></a>
     );
}
 
export default Button;