import LineBreak from './LineBreak'
import FB_IMG from '../assets/fb.png'
import IG_IMG from '../assets/ig.png'
import KM_IMG from '../assets/LOGO KM.svg'

// router components
import { useNavigate, NavLink} from 'react-router-dom'

const Footer = () => {

    // const navigate = useNavigate()

    return ( 
        <div className="chtp-footer">
            <LineBreak />
            <div className="chtp-footer__container site-container">
                <div className="chtp-footer__columns">
                    <ul className="chtp-footer__column">
                        <NavLink to="o-akcji"><li>O akcji</li></NavLink>
                        <NavLink to="partnerzy"><li>Partnerzy</li></NavLink>
                        <NavLink to="harmonogram"><li>Harmonogram i zasady</li></NavLink>
                        <NavLink to="kim-jestesmy"><li>Kim jesteśmy?</li></NavLink>
                    </ul>
                    <ul className="chtp-footer__column">
                        <li><b>Zaloguj się</b></li>
                        <li><b>Kontakt: <a href="mailto:chcetoprzezyc@katalogmarzen.pl">chcetoprzezyc@katalogmarzen.pl</a></b></li>
                        <li>Regulamin</li>
                        <li>Najczęściej zadawane pytania</li>
                        <li>Polityka prywatności</li>
                    </ul>
                    <div className="chtp-footer__column chtp-footer__newsletter-column">
                        <p><b>Zapisz się do Newslettera:</b></p>
                        <form action="https://inspiracje.katalogmarzen.pl/add_subscriber.html" accept-charset="utf-8" method="post">
                            {/* <!-- Pole email (wymagane) --> */}
                            <div className="chtp-footer__newsletter-email-sign">
                                <input type="text" name="email" placeholder="Twój e-mail"/>
                                <input type="hidden" name="campaign_token" value="yoDii" />
                                {/* <!-- Strona z podziękowaniem (opcjonalnie) --> */}
	                            <input type="hidden" name="thankyou_url" value="https://www.google.com/"/>
                                {/* <!-- Przycisk zapisu --> */}
                                <input type="submit" value="ZAPISZ"/><br />
                            </div>
                            <input id="webform_consent#wqPz_0" type="checkbox" name="webform[consent#wqPz-ver#w5gI]" value="true" required /> 
                            <label htmlFor="webform_consent#wqPz_0">
                                Wyrażam zgodę na przesyłanie mi newslettera
                        i zapoznałam /zapoznałem się <a className="underline-link" href="https://katalogmarzen.pl/pl/polityka-prywatnosci" target="_blank" rel="nofollow noopener">informacją dot. przetwarzania danych osobowych</a> przez KatalogMarzeń.pl Joanna Zubik-Borucka, Tomasz Zubik s.c.</label> <br/>
                            {/* <!-- Token listy --> */}
                            {/* <!-- Pobierz token na: https://inspiracje.katalogmarzen.pl/campaign_list.html --> */}
                        </form>
                    </div>
                    <div className="chtp-footer__column chtp-footer__logos">
                        <div className="chtp-footer__socials">
                            <a href="https://www.instagram.com/chcetoprzezyc/" target="_blank"><img src={IG_IMG} alt="chtp-facebook" /></a>
                            <img src={FB_IMG} alt="chtp-facebook" />
                        </div>
                        <img src={KM_IMG} alt="chtp-km" />
                    </div>
                </div>
                <div className="chtp-footer__copyrights">
                    <p><b>© 2022 Chcę to przeżyć</b></p>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;