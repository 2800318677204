import LineBreak from "./LineBreak";

interface Props {
    title: string;
    bg: string
}

const MainPhoto = ({title, bg}:Props) => {
    return ( 
        <>
            <LineBreak />
            <div id="main-photo" className={`chtp-main-photo ${bg}`}>
                <h1>{title}</h1>
            </div>
        </>
     );
}

export default MainPhoto;