import ChtpNumbers from "../components/ChtpNumbers";
import Nagrody from "../components/Nagrody";
import WezUdzial from "../components/WezUdzial";

import Hash from "../assets/chtp-hash.svg";
import HashWhite from "../assets/chtp-hash-white.svg";
import OKonkursie from "../assets/home-o-konkursie.jpg";

const Home = () => {
  return (
    <div className="chtp-home">
      <div className="chtp-home__main-photo">
        <h1 className="chtp-home__main-title">
          Przeżyj to z nami!
          <br /> Poznaj akcję <br /> #ChcęToPrzeżyć
        </h1>
      </div>
      <div className="site-container">
        <section className="chtp-home__o-konkursie">
          <div className="chtp-home__o-konkursie-columns">
            <div className="chtp-home__o-konkursie-text">
              <div className="chtp-home__o-konkursie-title-container">
                <img src={Hash} alt="" />
                <h2>O konkursie:</h2>
              </div>
              <p className="chtp-home__o-konkursie-description">
              Chcesz żyć pełnią życia i spełniać marzenia, ale przeżycia wydają się być poza Twoim zasięgiem? Potrzebujesz impulsu i wsparcia? Jesteś we właściwym miejscu! 
              </p>
              <p className="chtp-home__o-konkursie-description">
              Weź udział w akcji <b>#ChcęToPrzeżyć</b> i zostań Testerem Marzeń. Wypełnij zgłoszenie, a my damy Ci za darmo voucher, z którym spełnisz swoje marzenie. Dołącz do niezwykłej społeczności, która liczy już ponad 2500 osób. 
              </p>
              <p className="chtp-home__o-konkursie-description">
              Główną wygraną w konkursie jest abonament na spełnianie marzeń. Chcesz zmienić swoje życie? To najlepszy moment!
              </p>
              <ul className="chtp-home__o-konkursie-list">
                <li className="chtp-home__o-konkursie-list-item">
                  <p>1</p>
                  <p>
                    <b>Zarejestruj się.</b> Przekonaj nas, że to Ty będziesz
                    najlepszym Testerem Marzeń, a następnie wybierz marzenie.
                  </p>
                </li>
                <li className="chtp-home__o-konkursie-list-item">
                  <p>2</p>
                  <p>
                    <b>Zrealizuj marzenie.</b> Pochwal się, jak było? Opublikuj
                    relacje w social mediach z odpowiednimi oznaczeniami.
                  </p>
                </li>
                <li className="chtp-home__o-konkursie-list-item">
                  <p>3</p>
                  <p>
                    <b>Przechodź do kolejnych etapów.</b> Realizuj kolejne
                    marzenia. Walcz o nagrodę główną.
                  </p>
                </li>
              </ul>
              <p className="chtp__button chtp__white-button">
                DOWIEDZ SIĘ WIĘCEJ
              </p>
            </div>
            <div className="chtp-home__o-konkursie-photo">
              <img src={OKonkursie} alt="" />
            </div>
          </div>
          <div className="chtp-home__o-konkursie-stripe-container">
            <div className="chtp-home__o-konkursie-stripe">
              <ul className="chtp-home__o-konkursie-stripe-items">
                <li className="chtp-home__o-konkursie-stripe-item">
                  <p>24.04</p>
                  <span>START</span>
                </li>
                <li className="chtp-home__o-konkursie-stripe-item">
                  <p>24.04</p>
                  <span>Nazwa etapu</span>
                </li>
                <li className="chtp-home__o-konkursie-stripe-item">
                  <p>24.04</p>
                  <span>Nazwa etapu</span>
                </li>
                <li className="chtp-home__o-konkursie-stripe-item">
                  <p>24.04</p>
                  <span>Nazwa etapu</span>
                </li>
                <li className="chtp-home__o-konkursie-stripe-item">
                  <p>24.04</p>
                  <span>FINAŁ</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <Nagrody />
        <ChtpNumbers />
      </div>
      <div className="chtp-home__marzyciele">
        <div className="chtp-home__marzyciele-title-container">
          <img src={HashWhite} alt="" />
          <h2>Marzyciele o akcji</h2>
        </div>
        <div className="chtp-home__marzyciele-items site-container">
          <div className="chtp-home__marzyciele-item">
            <div className="chtp-home__marzyciele-item-text">
              <p>Imię i nazwisko</p>
              <p>{">"}</p>
            </div>
            <div className="chtp-home__marzyciele-item--hover">
              <p>Imię i nazwisko</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci ipsum nam quas nobis et ipsam quibusdam porro, odit
                labore praesentium magnam quo ratione debitis nisi voluptate
                dicta eius quae iure?
              </p>
            </div>
          </div>
          <div className="chtp-home__marzyciele-item">
            <div className="chtp-home__marzyciele-item-text">
              <p>Imię i nazwisko</p>
              <p>{">"}</p>
            </div>
            <div className="chtp-home__marzyciele-item--hover">
              <p>Imię i nazwisko</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci ipsum nam quas nobis et ipsam quibusdam porro, odit
                labore praesentium magnam quo ratione debitis nisi voluptate
                dicta eius quae iure?
              </p>
            </div>
          </div>
          <div className="chtp-home__marzyciele-item">
            <div className="chtp-home__marzyciele-item-text">
              <p>Imię i nazwisko</p>
              <p>{">"}</p>
            </div>
            <div className="chtp-home__marzyciele-item--hover">
              <p>Imię i nazwisko</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci ipsum nam quas nobis et ipsam quibusdam porro, odit
                labore praesentium magnam quo ratione debitis nisi voluptate
                dicta eius quae iure?
              </p>
            </div>
          </div>
          <div className="chtp-home__marzyciele-item">
            <div className="chtp-home__marzyciele-item-text">
              <p>Imię i nazwisko</p>
              <p>{">"}</p>
            </div>
            <div className="chtp-home__marzyciele-item--hover">
              <p>Imię i nazwisko</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci ipsum nam quas nobis et ipsam quibusdam porro, odit
                labore praesentium magnam quo ratione debitis nisi voluptate
                dicta eius quae iure?
              </p>
            </div>
          </div>
          <div className="chtp-home__marzyciele-item">
            <div className="chtp-home__marzyciele-item-text">
              <p>Imię i nazwisko</p>
              <p>{">"}</p>
            </div>
            <div className="chtp-home__marzyciele-item--hover">
              <p>Imię i nazwisko</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci ipsum nam quas nobis et ipsam quibusdam porro, odit
                labore praesentium magnam quo ratione debitis nisi voluptate
                dicta eius quae iure?
              </p>
            </div>
          </div>
          <div className="chtp-home__marzyciele-item">
            <div className="chtp-home__marzyciele-item-text">
              <p>Imię i nazwisko</p>
              <p>{">"}</p>
            </div>
            <div className="chtp-home__marzyciele-item--hover">
              <p>Imię i nazwisko</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci ipsum nam quas nobis et ipsam quibusdam porro, odit
                labore praesentium magnam quo ratione debitis nisi voluptate
                dicta eius quae iure?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-container chtp__container-padding">
        <WezUdzial />
      </div>
    </div>
  );
};

export default Home;
