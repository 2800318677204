import {useState} from  'react'

import photo1 from '../assets/harmonogram_bg_1.jpg';
import photo2 from '../assets/harmonogram_bg_2.jpg';
import photo3 from '../assets/kim-jestesmy_bg_1.jpg';
import photo4 from '../assets/kim-jestesmy_bg_2.jpg';
import photo5 from '../assets/kim-jestesmy-activities1.jpg';
import photo6 from '../assets/kim-jestesmy-activities2.jpg';
import photo7 from '../assets/kim-jestesmy-activities3.jpg';
import photo8 from '../assets/o-akcji_bg_1.jpg';
import photo9 from '../assets/o-akcji_bg_2.jpg';
import photo10 from '../assets/partnerzy_1.jpg';
import photo11 from '../assets/partnerzy_bg_1.jpg';
import photo12 from '../assets/partnerzy_bg_2.jpg';

const GridGallery = () => {
    const [photos, setPhotos] = useState<any[]>([photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9, photo10, photo11, photo12])

    let [overlayVisible, setOverlayVisible] = useState<boolean>(false);
    let [activePhotoIndex, setActivePhotoIndex] = useState<number>(0);
    let [touchStart, setTouchStart] = useState<number>(0);

    const handleGalleryImageClick = (id:number) => {
        setOverlayVisible(true)
        setActivePhotoIndex(id)
        document.body.classList.add('body-modal-open')
    }

    const handleOverlayClick = (e:any) => {
        if(!e.target.dataset.arrow){
            setOverlayVisible(false)
            document.body.classList.remove('body-modal-open')
        }
        
    }

    const handleOverlayArrowClick = (number:number) => {
        let newActivePhotoIndex = activePhotoIndex + number
        newActivePhotoIndex < 0 && (newActivePhotoIndex = photos.length - 1)
        newActivePhotoIndex > photos.length - 1 && (newActivePhotoIndex = 0)
        setActivePhotoIndex(newActivePhotoIndex)
    }

    const handleTouchStart = (e:any) => {
        setTouchStart(e.touches[0].clientX)
    }

    const handleTouchEnd = (e:any) => {
        let touchEnd = e.changedTouches[0].clientX
        let diff = touchEnd - touchStart

        if (diff > 100) handleOverlayArrowClick(-1)
        if (diff < -100) handleOverlayArrowClick(1)
    }

    return (
        <>
        <div className='chtp__grid-gallery'>
            {photos.map(item => (
                <img onClick={() => handleGalleryImageClick(photos.findIndex(it => it === item))} src={item}
                key={item}
                alt="" />
            ))}
        </div>
        <div 
        onClick={handleOverlayClick} className={`chtp__grid-gallery-overlay ${overlayVisible && 'chtp__grid-gallery-overlay--active'}`}
        onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
        >
            <span data-arrow="left" onClick={() => handleOverlayArrowClick(-1)} className="chtp__grid-gallery-overlay-arrow chtp__grid-gallery-overlay-arrow--left">&lsaquo;</span>
            <img src={photos[activePhotoIndex]} alt="" />
            <span data-arrow="right" onClick={() => handleOverlayArrowClick(1)} className="chtp__grid-gallery-overlay-arrow chtp__grid-gallery-overlay-arrow--right">&rsaquo;</span>
        </div>
        </>
     );
}

export default GridGallery;